import feeplanContent from './feeplan-content.yml';
import acpFeeplanFilter from './acp-feeplan-filter';
import acpFeeplanClient from './acp-feeplan-client';
import acpFeeplanModel from './acp-feeplan-model';
import acpFeeplanCtrl from './acp-feeplan-ctrl';
import acpFeeplanDirective from './acp-feeplan-directive';
import acpExternalAccountService from 'components/external-account-service';
import acpCore from 'core';
import ng from 'angular';
import './styles/${theme}/core.scss';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.feeplan
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-feeplan></acp-feeplan>
 * ```
 *
 * ```js
 * angular.module('acp.component.feeplan.example', [
 *   require('acp.component.feeplan').name
 * ]);
 * ```
 */
var component = ng
  .module('acp.component.feeplan', [
    acpCore.name,
    acpExternalAccountService.name
  ])
  .directive('acpFeeplan', acpFeeplanDirective)
  .controller('acpFeeplanDirectiveCtrl', acpFeeplanCtrl)
  .service('acpFeeplanModel', acpFeeplanModel)
  .service('acpFeeplanClient', acpFeeplanClient)
  .filter('acpFeeplanFilter', acpFeeplanFilter)
  .run(
    /*@ngInject*/ function(contentSectionCache) {
      contentSectionCache.put('components/feeplan', feeplanContent);
    }
  );

export default component;
