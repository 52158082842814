// @ngInject
function AcpFeePlanClient(webApiResource) {
  this.get = webApiResource({
    path: '/v1/feeplan'
  });

  this.getAll = webApiResource({
    path: '/v1/feeplan/available'
  });

  this.subscribe = webApiResource({
    method: 'PUT',
    path: '/v1/feeplan/:id'
  });
}

export default AcpFeePlanClient;
