import acpFeeplanTemplate from './templates/acp-feeplan.html';
// @ngInject
function acpFeeplanDirective() {
  return {
    restrict: 'E',
    scope: {},
    template: acpFeeplanTemplate
  };
}

export default acpFeeplanDirective;
