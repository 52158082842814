import ng from 'angular';

// @ngInject
function AcpFeePlanModel(acpFeeplanClient, nsUtil) {
  this.getFeePlanName = function() {
    return acpFeeplanClient.get().then(function(response) {
      // Used for Google Analytics fee plan data layer variables
      return response.plan.flags.indexOf('pay_as_you_go') === -1 ? 'FAP' : 'PG';
    });
  };

  this.get = function() {
    return acpFeeplanClient.get().then(function(feeplan) {
      // Preserve the original feeplan object returned from the api
      var feePlanObj = ng.extend({}, feePlanObj, feeplan.plan);

      feePlanObj._cost = nsUtil.toDollars(feePlanObj.cost);
      decorateFeePlanFees(feePlanObj.fees);

      return feePlanObj;
    });
  };

  this.getAll = function() {
    return acpFeeplanClient.getAll().then(function(feeplans) {
      feeplans.plans.map(function(plan) {
        plan._cost = nsUtil.toDollars(plan.cost);
        decorateFeePlanFees(plan.fees);
        return plan;
      });

      return feeplans;
    });
  };

  this.subscribe = function(data) {
    return acpFeeplanClient.subscribe(data);
  };

  function decorateFeePlanFees(fees) {
    /*
      We iterate over the fees recursively
      If the key's value is another object continue iterating
      until we get to a value that is not a new object and is not a string value
     */
    for (var key in fees) {
      if (fees.hasOwnProperty(key)) {
        if (typeof fees[key] === 'object') {
          decorateFeePlanFees(fees[key]);
        } else if (
          typeof fees[key] !== 'object' &&
          typeof fees[key] !== 'string'
        ) {
          fees[key] = nsUtil.toDollars(fees[key]);
        }
      }
    }

    return fees;
  }
}

export default AcpFeePlanModel;
