// @ngInject
function acpFeeplanCtrl(
  $scope,
  acpTermsModel,
  acpFeeplanModel,
  nsPermissions,
  nsUtil,
  content,
  externalAccountModel,
  acpDeviceService,
  acpInAppBrowserModel,
  $window
) {
  $scope.state = {
    hasUnexpectedError: false
  };

  function init() {
    acpTermsModel.getTermsUrl().then(termsUrlSuccess, apiError);
    acpFeeplanModel.get().then(feePlanSuccess, apiError);

    nsPermissions
      .requestPermissions([
        'isSmallBusiness',
        'isSmallBusinessMaster',
        'isExpenseManagement',
        'subaccountEnabledAccount'
      ])
      .then(onPermissionsResult)
      .catch(onUnexpectedError);
  }

  function termsUrlSuccess(termsUrl) {
    $scope.termsUrl = termsUrl;
  }

  function onPermissionsResult(permissions) {
    $scope.permissions = permissions;
    loadTokenHeaderForExternalFeePlan();
  }

  function apiError() {
    $scope.error = true;
  }

  function setState(state) {
    nsUtil.forOwn($scope.state, function(value, key) {
      if (state === key) {
        $scope.state[key] = true;
      } else {
        $scope.state[key] = false;
      }
    });
  }

  function feePlanSuccess(data) {
    $scope.feePlan = data;
  }

  function onUnexpectedError() {
    setState('hasUnexpectedError');
  }

  $scope.showSubaccountDisclosure = function() {
    if ($scope.permissions) {
      return (
        $scope.permissions.isSmallBusiness &&
        !$scope.permissions.isExpenseManagement
      );
    } else {
      return false;
    }
  };

  $scope.showManageBilling = function() {
    if ($scope.permissions) {
      return (
        $scope.permissions.isExpenseManagement &&
        $scope.permissions.isSmallBusinessMaster
      );
    } else {
      return false;
    }
  };

  function loadTokenHeaderForExternalFeePlan() {
    if (
      $scope.permissions.isExpenseManagement &&
      $scope.permissions.isSmallBusinessMaster
    ) {
      externalAccountModel.getExternalAccountInfo().then(function(accountData) {
        content
          .getKey('manage-billing-url', 'components/feeplan')
          .then(function(data) {
            $scope.manageBillingUrl =
              data.copy + accountData.hosted_login_token;
          });
      });
    }
  }

  $scope.redirectToRecurly = function() {
    if (acpDeviceService.isCordova()) {
      acpInAppBrowserModel.create($scope.manageBillingUrl);
    } else {
      $window.open($scope.manageBillingUrl, '_blank');
    }
  };

  init();
}

export default acpFeeplanCtrl;
