// @ngInject
function AcpFeePlanFilter($filter, nsUtil) {
  return function(input, isTransaction) {
    if (isNaN(input)) {
      return input;
    }
    if (input === 0) {
      return isTransaction ? 'UNLIMITED' : 'NO FEE';
    }
    return $filter('currency')(nsUtil.toDollars(input));
  };
}

export default AcpFeePlanFilter;
